import { Container } from "./styles";
import githubIcon from "../../assets/github.svg"
// import DownloadApp from '../../assets/download.png'
import externalLink from "../../assets/external-link.svg"
import ScrollAnimation from "react-animate-on-scroll";


export function Project() {
  return (
    <Container id="project">
      <h2>My Services</h2>
      <div className="projects">

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#23ce6b" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"> <title>Folder</title> <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path> </svg>
           
            </header>
            <div className="body">
              <h3>YouTubers' Best Brand Deals
</h3>
              <p>We connect YouTubers with top-tier brand deals, ensuring they receive lucrative opportunities that align with their content and audience. Our network and expertise help creators maximize their earnings and brand partnerships. </p>
            </div>
           
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#23ce6b " strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"><title>Folder</title> <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path> </svg>
             
            </header>
            <div className="body">
              <h3>Best YouTubers for Promotions
</h3>
              <p>
                We identify the most suitable YouTubers for brands' promotional campaigns. By matching brands with influencers whose audience demographics and engagement fit their target market, we enhance the effectiveness of their marketing strategies.
              </p>
            </div>
        
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#23ce6b" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              
            </header>
            <div className="body">
              <h3>Best Services to Agencies
</h3>
              <p>
                We provide comprehensive support to marketing agencies, including influencer sourcing, campaign management, and performance analytics. Our services ensure seamless execution and optimal results for all social media campaigns.
              </p>
            </div>
            
          </div>
        </ScrollAnimation>



      

        

      </div>
    </Container>
  );
}